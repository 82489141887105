.quote-card-view {
    margin: 25px;
    padding: 25px;
  }
  
  .quote-text {
    text-align: justify;
    margin: 0;
    line-height: 1.8;
    word-break: break-word;
  }
  
  .purple {
    color: purple;
  }
  
  .about-activity {
    list-style-type: none;
    margin: 5px 0;
  }
  
  @media (max-width: 576px) {
    .quote-text {
      text-align: left; /* Override justify alignment for small screens */
    }
  }
  